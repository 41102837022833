import React from 'react';
import { graphql } from 'gatsby';
import cx from 'classnames';

import * as styles from './styles/blogDetails.module.scss';
import {
  BlogHero,
  Desktop,
  FluidImage,
  Mobile,
  Image,
  TextParser,
} from '../components';

import {
  IBlogDetails,
  IPageContext,
  IImage,
  ITourItem,
  IPageBlogs,
} from '../interfaces';

import { PageTours, PageBlogs } from '../elements';
import withMeta from '../hocs/withMeta';

interface IProps {
  data: {
    contentfulBlog: IBlogDetails;
    contentfulTranslationsVarious: {
      blogCarouselTitle: string;
    };
  };
  pageContext: IPageContext;
}

interface ICarousel {
  carousel: boolean | undefined;
}

function renderBlogs(pageContext: IPageContext, blogCarousel: IPageBlogs) {
  return (
    <React.Fragment>
      <div>
        <PageBlogs pageBlogs={blogCarousel} pageContext={pageContext} />
      </div>
    </React.Fragment>
  );
}

function renderImages(images: IImage[]) {
  return (
    <React.Fragment>
      {images.map((elem, index) => {
        return (
          <div className={styles[`image${index}`]} key={index}>
            <Image
              fluidImage={{
                ...elem.fluid,
                fluid: {
                  ...(elem ? elem.fluid : null),
                  sizes: '(max-width: 950px)  100vw, 450px',
                  alt: elem.title,
                },
              }}
              className={styles[`blogImage${index}`]}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
}

function renderImagesMobile(images: IImage[]) {
  return (
    <React.Fragment>
      <div className='columns is-multiline is-mobile is-2'>
        {images.map((elem, index) => {
          return (
            <div className={'column is-full'} key={index}>
              <FluidImage
                fluid={elem.fluid}
                className={styles.blogImageMobile}
                alt={elem.title}
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

function renderMainImage(mainImage: IImage) {
  return (
    <React.Fragment>
      <div className={styles.image0}>
        <FluidImage
          fluid={mainImage.fluid}
          className={styles.blogImage0}
          alt={mainImage.title}
        />
      </div>
    </React.Fragment>
  );
}

function renderTours(
  tours: ITourItem[],
  title: string,
  pageContext: IPageContext,
  carousel: ICarousel
) {
  return (
    <React.Fragment>
      <div>
        <PageTours
          pageTours={{ title, tours }}
          pageContext={pageContext}
          carousel={!!carousel}
        />
      </div>
    </React.Fragment>
  );
}

const BlogDetails: React.FunctionComponent<IProps> = (props) => {
  const { mainImage, mainText, images, tours, carousel } =
    props.data.contentfulBlog;

  const { blogCarouselTitle } = props.data.contentfulTranslationsVarious;

  let imageContainer: number;
  if (images === undefined || images === null || images.length === 0) {
    imageContainer = 1;
  } else {
    imageContainer = images.length;
  }

  return (
    <React.Fragment>
      <Desktop>
        <BlogHero data={props.data} pageContext={props.pageContext} />
        <div className={cx(styles.container)}>
          <div className={cx('centered-content')}>
            <div className={cx(styles[`main${imageContainer}`])}>
              <div className={styles[`imageContainer${imageContainer}`]} />
              <TextParser
                source={mainText.mainText}
                className={styles.mainText}
              />
              {!images
                ? mainImage
                  ? renderMainImage(mainImage)
                  : null
                : renderImages(images)}
              <div className={styles.greyLine} />
            </div>
            {tours === undefined || tours === null || tours.length === 0
              ? ''
              : renderTours(
                  tours,
                  blogCarouselTitle,
                  props.pageContext,
                  carousel
                )}
          </div>
          {renderBlogs(
            props.pageContext,
            props.data.contentfulBlog.blogCarousel
          )}
        </div>
      </Desktop>
      <Mobile>
        <BlogHero data={props.data} pageContext={props.pageContext} />
        <div className={cx(styles.container)}>
          <div className={styles.mobileContainer}>
            <TextParser
              source={mainText.mainText}
              className={styles.mainText}
            />
          </div>
          {images === undefined || images === null || images.length === 0
            ? null
            : renderImagesMobile(images)}
          {tours === undefined || tours === null || tours.length === 0
            ? ''
            : renderTours(
                tours,
                blogCarouselTitle,
                props.pageContext,
                carousel
              )}
        </div>
        {renderBlogs(props.pageContext, props.data.contentfulBlog.blogCarousel)}
      </Mobile>
    </React.Fragment>
  );
};

export const query = graphql`
  query BlogDetailsQuery($slug: String!, $locale: String!) {
    contentfulBlog(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      ...BlogDetails
    }

    contentfulTranslationsVarious(node_locale: { eq: $locale }) {
      blogCarouselTitle
    }
  }
`;

export default withMeta(BlogDetails);
