// extracted by mini-css-extract-plugin
export var image = "blogDetails-module--image--1Qyhk";
export var mainText = "blogDetails-module--mainText--3_YQE";
export var container = "blogDetails-module--container--3Fnn_";
export var main1 = "blogDetails-module--main1--19VhV";
export var main2 = "blogDetails-module--main2--1D09C";
export var main3 = "blogDetails-module--main3--J4chB";
export var imageContainer3 = "blogDetails-module--imageContainer3--hUmhf";
export var imageContainer2 = "blogDetails-module--imageContainer2--2spj7";
export var imageContainer1 = "blogDetails-module--imageContainer1--3J6Ab";
export var title = "blogDetails-module--title--2DmcV";
export var blogImage0 = "blogDetails-module--blogImage0--1joQJ";
export var image0 = "blogDetails-module--image0--29oUU";
export var blogImage1 = "blogDetails-module--blogImage1--3ljWy";
export var image1 = "blogDetails-module--image1--1giSR";
export var blogImage2 = "blogDetails-module--blogImage2--3RnWL";
export var image2 = "blogDetails-module--image2--1GKQW";
export var greyLine = "blogDetails-module--greyLine--3pVvM";
export var mobileContainer = "blogDetails-module--mobileContainer--1SGF2";
export var blogImageMobile = "blogDetails-module--blogImageMobile--suci_";